import React, { useState } from "react";
import Button from "../../common/Button/Button";
import SadaqatNews1 from "./sadaqatnews1";
import myVideo from '../../videos/carpet (1).mp4';
import myVideo1 from '../../videos/carpet (2).mp4';
import myVideo2 from '../../videos/carpet (3).mp4';
import './News.css'; // Import custom CSS for styling

const MakeAnImpact = () => {
  const [copySuccess, setCopySuccess] = useState(false);
  const [isSadaqatNews1Open, setIsSadaqatNews1Open] = useState(false);

  const openSadaqatNews1 = () => {
      setIsSadaqatNews1Open(true);
  };

  const sharePage = () => {
    const shareData = {
      title: "Share the Good News",
      url: "https://actionnotword.org/news",
    };

    if (navigator.share) {
      navigator.share(shareData)
        .then(() => console.log('Page shared successfully!'))
        .catch((error) => console.error('Error sharing:', error));
    } else {
      navigator.clipboard.writeText(shareData.url)
        .then(() => {
          setCopySuccess(true);
          setTimeout(() => setCopySuccess(false), 5000); // Hide message after 5 seconds
        })
        .catch((error) => console.error('Failed to copy URL:', error));
    }
  };

  return (
    <div>
      {isSadaqatNews1Open && (
        <SadaqatNews1 isOpen={isSadaqatNews1Open} setIsOpen={setIsSadaqatNews1Open} />
      )}

      <div className="makeAnImpact" data-aos="fade-right">
        <h1> Certificate Distribution Ceremony at Sedaqat & Danish Education Centers </h1>
        <div className="donateSection" data-aos="fade-up">
          <p>
            On 28 of August 2024, Sedaqat and Danish Education Centers held a ceremony to honor our top students with
            certificates of achievement. The event recognized the hard work and dedication of our best-performing
            students, celebrating their academic success.
            Teachers, parents, and guests attended the ceremony, adding to the significance of the occasion.
            This event highlighted our ongoing commitment to excellence in education and the bright future of our students.
          </p>
          <div className="hero-btns">
            <div className="hero-btn-group">
              <Button
                className="btns"
                buttonStyle="btn--primary"
                buttonSize="btn--large"
                onClick={openSadaqatNews1}
              >
                Photos
              </Button>
              <button className="shareButton" onClick={sharePage}>
                  Share this Page
              </button>
              {copySuccess && <div className="copySuccessMessage">Link copied to clipboard!</div>}
            </div>  
          </div>
        </div>
      </div>
      <div className="makeAnImpact" data-aos="fade-right">
      <h1>Carpets & Foam Mats for Four Classrooms of a Girls' School</h1>
      <div className="donateSection" data-aos="fade-up">
        <p>
          Respected Ahmad Shah Fazel, a family member of Nader Jan Omar, generously provided $300 
          to assist deserving schools. As part of my responsibility, I purchased carpets and foam mats
          for four classrooms of a girls' school that previously lacked proper flooring. The students
          are now benefiting from this contribution. I wanted to share this report with you all!
          Special thanks to Ahmad Shah Fazel and Nader Jan Omar for facilitating this support!
        </p>
        <div className="videoContainer">
          <div>
            <video controls>
              <source src={myVideo} type="video/mp4" />
            </video>
          </div>
          <div>
            <video controls>
              <source src={myVideo1} type="video/mp4" />
            </video>
          </div>
          <div>
            <video controls>
              <source src={myVideo2} type="video/mp4" />
            </video>
          </div>
        </div>
        <button className="shareButton" onClick={sharePage}>
          Share this Page
        </button>
        {copySuccess && <div className="copySuccessMessage">Link copied to clipboard!</div>}
      </div>
      <div className="makeAnImpact" data-aos="fade-right"></div>
      <h1>Eid- al- Adha Program</h1>
      <div className="donateSection" data-aos="fade-up">
        <p>
          In celebration of Eid- al- Adha, sweets were distributed among students of Sedaqat education center. In the meantime, students who completed the first level of English language arts class received their certificates of completion. 
          This is awesome and seeing the happiness in children’s faces is priceless. In my opinion, this is the best kind of Qurbani and Huj. 
          Eid Mubarak and Huj and Qurbani qabool!
        </p>
        <div className="hero-btns">
          <div className="hero-btn-group">
            <Button
              className="btns"
              buttonStyle="btn--primary"
              buttonSize="btn--large"
            >
              Photos
            </Button>
            <Button
              className="btns"
              buttonStyle="btn--primary"
              buttonSize="btn--large"
            >
              Videos
            </Button>
          </div>
        </div>
        <button className="shareButton" onClick={sharePage}>
          Share this Page
        </button>
        {copySuccess && <div className="copySuccessMessage">Link copied to clipboard!</div>}
        </div>
        
      </div>
    </div>
  );
};

export default MakeAnImpact;
