import React from "react";

const Mission = () => {
  return (
    <div className="mission" data-aos="fade-up">
      <h1>Our Mission</h1>
      <p>
        The mission of Action Not Word is to provide monetary and educational assistance to needy schools in Afghanistan. We believe the success of the country will stem from a generation of children with the opportunity to attain a quality education. Our goal is to strengthen the community by investing in local schools to provide a safe and enriching learning experience, empowering the youth of Afghanistan to achieve a better life.
      </p>
    </div>
  );
};

export default Mission;
