import React from "react";
import Navbar from "./common/Navbar/Navbar.jsx";
import ScrollToTop from "./common/Utils/ScrollToTop.jsx";
import Footer from "./common/Footer/Footer.jsx";
import Home from "./components/Home/Home.jsx";
import About from "./components/About/About.jsx";
import Donate from "./components/Donate/Donate.jsx";
import News from "./components/News/News.jsx";
import Activity from "./components/Activity/Activity.jsx";
import Projects from "./components/Projects/Projects.jsx";
import OHA from "./components/Projects/OHA.jsx";
import SEC from "./components/Projects/SEC.jsx";
import ContactUs from "./components/ContactUs/ContactUs.jsx";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import "./app.css";


function App() {
  return (
    <Router>
      <Navbar />
      <ScrollToTop />
      <Switch>
        <Route path="/" exact component={Home} />
        <Route path="/about" exact component={About} />
        <Route path="/donate" exact component={Donate} />
        <Route path="/news" exact component={News} />
        <Route path="/activities" exact component={Activity} />
        <Route path="/projects" exact component={Projects} />
        <Route path="/oha" exact component={OHA} />
        <Route path="/sec" exact component={SEC} />
        <Route path="/contact-us" exact component={ContactUs} />
      </Switch>
      <Footer />
    </Router>
  );
}

export default App;
