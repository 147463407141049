import React, { useEffect } from "react";
import HeroSection from "./HeroSection";
import Mission from "./Mission";
import LatestProject from "./LatestProject";
import Button from "../../common/Button/Button";
import AOS from "aos";
import "aos/dist/aos.css";
import "./Home.css";

const Home = () => {
  useEffect(() => {
    AOS.init({ duration: 1000 });
  }, []);

  return (
    <div>
      <HeroSection />
      <Mission />
      <hr />
      <div className="activities-button" data-aos="fade-up">
        <h1>Highlights of our recent activities</h1>
        <Button
          className="btns"
          buttonStyle="btn--primary"
          buttonSize="btn--large"
          src="activities"
          newTab="false"
        >
          Check it out
        </Button>
      </div>
      <hr />
      <LatestProject />
    </div>
  );
};

export default Home;
